<template>
  <v-container
    id="extended-tables"
    fluid
    tag="section"
    class="grey lighten-4"
  >
    <!-- Default orgTitle value is UDA Consulting -->
    <base-v-component
      id="v-step-0"
      :organization="orgTitle"
      :heading="$t('section-title.active-forms')"
      :link="helplink"
      :click="startTour()"
    />
    <!-- Active forms card -->
    <base-material-card
      icon="mdi-earth"
    >
      <template v-slot:after-heading>
        <div
          data-v-step="1"
          class="font-weight-light card-title mt-2"
        >
          {{ $t('sections.active-forms') }}
        </div>
      </template>
      <v-card-text>
        <!-- search field -->
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            outlined
            dense
            append-icon="mdi-magnify"
            class="ml-auto"
            :label="$t('common.search')"
            hide-details
            single-line
            style="max-width: 250px;"
          />
        </v-toolbar>
        <!-- Data table -->
        <v-progress-linear
          v-if="!orgsubsccalled"
          indeterminate
        />
        <v-data-table
          v-else
          id="v-step-2"
          :items="activeForms"
          class="row-pointer v-step-0"
          :headers="activeheaders"
          :loading="formLoad"
          :search.sync="search"
          :sort-by="['startDate', 'title']"
          :sort-desc="[true, false]"
          item-key="syncDate"
          multi-sort
          @click:row="rowClicked"
        >
          <!-- Action buttons -->
          <!-- <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip
              v-for="a in actionbuttons"
              :key="a.path"
              bottom
            >
              <template #activator="{ on }">
                <v-btn
                  class="v-step-3"
                  small
                  icon
                  dark
                  :disabled="loadDialog"
                  @click.stop="formActions({ form: item, button: a})"
                >
                  <v-icon
                    :color="a.color"
                    v-on="on"
                  >
                    {{ a.icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ a.tooltip }}</span>
            </v-tooltip>
          </template> -->

          <!-- No Data button -->
          <template v-slot:no-data="">
            <v-btn to="/account/projects">
              Add Form in Projects page
            </v-btn>
          </template>

          <!-- Navigate on click title
          <template v-slot:item.name="props">
            <span
              style="cursor: pointer;"
              @click="handleClick(props.item)"
            >
              {{ props.item.name }}
            </span>
          </template>-->

          <!-- Sync time ago -->
          <template v-slot:[`item.syncDate`]="props">
            <timeago
              :datetime="('syncDate' in props.item) ? props.item.syncDate : Date()"
              :locale="$i18n.locale"
              :auto-update="60"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
    <div class="py-3" />

    <!-- Dialogs -->
    <v-row justify="center">
      <!-- Not Activated -->
      <v-dialog
        v-model="dialogBanned"
        persistent
        max-width="420px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('active-forms.not-active') }}</span>
          </v-card-title>
          <v-card-text>
            {{ $t('active-forms.not-activated') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="success"
              text
              :to="{ name: 'Logout' }"
            >
              {{ $t('common.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Add Organization -->
      <v-dialog
        v-model="dialogOrganization"
        persistent
        max-width="420px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('active-forms.register-organization') }}</span>
          </v-card-title>
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(postOrgActions)">
              <v-card-text>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('payment.subscription')"
                  rules="required"
                >
                  <v-select
                    v-model="selectedSubscription"
                    color="secondary"
                    outlined
                    item-color="secondary"
                    :items="pricing"
                    :label="$t('payment.subscription')"
                    :item-text="$t('title')"
                    item-value="code"
                    dense
                    :error-messages="errors"
                    return-object
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('account-settings.organization-name')"
                  :rules="{ required: true, regex: /^[a-zA-Z0-9\s]+$/, max:50 }"
                >
                  <v-text-field
                    v-model="orgName"
                    class="mt-6 mb-4"
                    outlined
                    counter="50"
                    dense
                    :error-messages="errors"
                    color="secondary"
                    :label="$t('account-settings.organization-name')"
                    :placeholder="$t('active-forms.add-orgname')"
                    :hint="$t('hint.letter-numss')"
                  />
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <v-textarea
                    v-model="orgDescription"
                    :error-messages="errors"
                    outlined
                    rows="2"
                    color="secondary"
                    :label="$t('form-settings.description')"
                    :placeholder="$t('active-forms.about-organization')"
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="success"
                  text
                  type="submit"
                >
                  {{ $t('common.register') }}
                </v-btn>
              </v-card-actions>
            </form>
          </validation-observer>
        </v-card>
      </v-dialog>

      <!-- Loading dialog -->
      <v-dialog
        v-model="loadDialog"
        hide-overlay
        persistent
        min-width="250"
        max-width="400"
      >
        <v-card
          color="warning"
          dark
        >
          <v-card-text
            class="white--text"
          >
            {{ loadText }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-tour
      name="myTour"
      :steps="tourSteps"
    />
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import VueTimeago from 'vue-timeago'
  import { useBendStore } from '@/stores/bendStore'
  import { useSubscriptionStore } from '@/stores/SubscriptionStore'
  // import { storeToRefs } from 'pinia'

  // Mixins
  import organization from '@/mixins/organization'
  import projects from '@/mixins/projects'
  import webusers from '@/mixins/webusers'
  import userAccess from '@/mixins/user-access'

  const subscription = useSubscriptionStore()

  Vue.use(VueTimeago, {
    name: 'Timeago', // Component name, `Timeago` by default
    locale: 'en-US', // Default locale
    locales: {
      pt: require('date-fns/locale/pt'),
      ru: require('date-fns/locale/ru'),
      tr: require('date-fns/locale/tr'),
    },
  })

  useBendStore()

  export default {
    name: 'ProfileFormsActive',

    components: {
      // Notification
      // PagesHeading: () => import('./../components/Heading'),
    },

    mixins: [
      organization,
      webusers,
      projects,
      userAccess,
    ],

    data: () => ({
      isOrganization: true,
      isProject: false,
      isActiveForm: true,
      user: {},
      projects: [],
      abuttonactive: false,
      // aForms: [],

      allProject: [],
      formLoad: true,

      search: undefined,

      orgName: '',
      orgDescription: '',
      dialogOrganization: false,
      dialogBanned: false,
      loadDialog: false,
      loadText: 'Please Stend by',
      selectedform: {},

      helplink: 'guide/02-initial-setup.html#register-a-project',

      selectedSubscription: null,

      tourSteps: [
        {
          target: '#v-step-0', // We're using document.querySelector() under the hood
          header: {
            title: 'Get Started with UDACAPI',
          },
          content: 'This is an <strong>Active survey forms</strong> section.',
          // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          params: {
            placement: 'bottom',
          },
        },
        {
          target: '[data-v-step="1"]',
          content: 'If you have any online forms you can see them here.',
          params: {
            placement: 'left',
          },
        },
        {
          target: '#v-step-2',
          content: 'You can click the row and navigate to the Monitoring Dashboard.',
          params: {
            placement: 'top', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
        {
          target: '.v-step-3',
          content: 'But first you need to activate Monitoring blocks for your Dashboard.<br>Click Monitoring Preferences to activate blocks',
          params: {
            placement: 'left', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
      ],
    }),

    computed: {
      orgTitle () {
        if (this.$store.state.bend.organization !== undefined) {
          return this.$store.state.bend.organization.title
        } else {
          return 'UDACAPI'
        }
      },

      // List of Active forms for rendering in template
      activeForms () {
        // console.log(this.projects)
        let active = []
        if (this.projects.length > 0) {
          this.projects.forEach(p => {
            if ('forms' in p) {
              // console.log(p)
              const a = p.forms.filter(f => (f.odksettings.state !== 'closed' && f.odksettings.phase !== 'draft' && f.odksettings.phase !== 'deployed'))
              // console.log(a)
              active = active.concat(a)
            }
          })
        }
        // console.log(active)
        return active
      },

      activeheaders () {
        return [
          { text: this.$t('table-header.title'), value: 'title' },
          { text: 'Project', value: 'project', align: 'start' },
          { text: this.$t('table-header.started'), value: 'startDate', align: 'end' },
          { text: this.$t('table-header.submissions'), value: 'submissions', align: 'end' },
          { text: this.$t('table-header.lastsync'), value: 'syncDate', align: 'end', sortable: false },
          // { text: this.$t('table-header.actions'), value: 'actions', align: 'end', sortable: false },
        ]
      },

      actionbuttons () {
        return [
          { id: 'dashboard', size: 'small', tooltip: this.$t('tooltip.dashboard'), icon: 'mdi-view-dashboard', color: 'indigo', to: 'Dashboard' },
          { id: 'monitor', size: 'small', tooltip: this.$t('tooltip.monitoring-pref'), icon: 'mdi-monitor-edit', color: 'secondary', to: 'Monitoring Preferences' },
          { id: 'sync', size: 'small', tooltip: this.$t('tooltip.sync'), icon: 'mdi-sync', color: 'secondary', to: '' },
          { id: 'settings', size: 'small', tooltip: this.$t('tooltip.settings'), icon: 'mdi-cog', color: 'warning', to: 'Active Form Settings' },
        ]
      },

      pricing () {
        // return translated names using .map()
        return subscription.pricing.map((data, i) => ({
          ...data,
          title: this.$t(data.heading),
        }))
      },

      orgsubsccalled () {
        return subscription.calls.orgsubsc
      },
    },

    mounted () {
      // If user selected plan assign it
      if (Object.keys(subscription.aSub).length !== 0) {
        this.selectedSubscription = subscription.aSub
      }

      this.initiate()
      // console.log(this.isVip())
    },

    methods: {

      async checkOrganization () {
        if (!this.$store.state.bend.calls.organization) {
          // if organization is setted getOrganization() returns true, else false
          // console.log(this.isOrganization)
          await this.getOrganization()
          // Get default server from ocpu
          this.$store.dispatch('ocpu/getDefServer')
          if (!('orgid' in this.$store.state.bend.organization)) {
            if (this.$store.state.auth.user.activated) {
              this.dialogOrganization = true
              this.formLoad = false
            } else {
              this.dialogBanned = true
            }
            return false
          } else {
            return true
          }
        }
        return true
      },

      async initiate () {
        this.user = this.$store.state.auth.user
        this.isOrganization = await this.checkOrganization()
        // console.log(this.user.activated)
        // if (this.user.activated) {
        // } else {
        //   this.dialogBanned = true
        // }

        // console.log(this.isOrganization)

        if (this.isOrganization) {
          // If Projects was not called run getProjects()
          if (!this.$store.state.bend.calls.projects) {
            this.formLoad = true
            // also check if there is organization added
            await this.getProjects()
          }
          this.projects = this.usersProjects()
          this.formLoad = false
          // console.log(this.projects)

          // If Webusers was not called run getWebuser()
          if (!this.$store.state.bend.calls.webuser) {
            await this.getWebuser()
          }
        }
        this.getSubscription()
      },

      async postOrgActions () {
        const org = await this.postOrganization()
        // console.log(org)
        if (org) {
          this.dialogOrganization = false
          await this.getWebuser()
          await this.genOrgFolder()
        }
        // Set 15 days trial subsription plan
        const startDate = new Date()
        const endDate = new Date(new Date().setDate(new Date().getDate() + 15))
        const data = {
          code: this.selectedSubscription.code,
          currency: '840',
          start: startDate.toLocaleDateString('en-CA'),
          end: endDate.toLocaleDateString('en-CA'),
          amount: 0,
          title: this.selectedSubscription.heading,
          orderid: (new Date()).toISOString().replace(/[^0-9]/g, ''),
          type: 'trial',
        }
        await this.setSubscription(JSON.stringify(data))
        // console.log(subsc)
      },

      rowClicked (first, second) {
        // console.log(first)
        this.$store.dispatch('main/setIndexes', first.ix).then(() => {
          this.$router.push({ name: 'Dashboard' })
        })
      },

      async formActions (item) {
        this.abuttonactive = true
        // console.log(item)
        this.selectedform = item
        // console.log(item.button)
        if (item.button.id === 'sync') {
          this.getSubmissions(item.form.ix)
        } else {
          // console.log(item.form.ix)
          this.$store.dispatch('main/setIndexes', item.form.ix).then(() => {
            this.$router.push({ name: item.button.to })
          })
        }
      },

      getSubmissions (ix) {
        this.loadDialog = true
        this.loadText = 'Checking server'
        // console.log(ix)
        const org = this.$store.state.bend.organization
        const prop = {
          orgid: org.orgid,
          prjid: org.projects[ix.pindex].prjid,
          formid: org.projects[ix.pindex].forms[ix.findex].odksettings.id,
        }
        const sclient = org.projects[ix.pindex].forms[ix.findex].submissions
        // console.log(prop)
        // Get number of submissions
        return this.$store
          .dispatch('ocpu/getSubmissions', prop)
          .then(sserver => {
            // console.log(sserver + ' ' + sclient)
            if (sserver > sclient) {
              // if data in server is bigger than in client...
              this.loadText = 'New data is available, starting to download'
              prop.sserver = sserver
              prop.projectid = org.projects[ix.pindex].forms[ix.findex].id
              prop.ix = ix
              // Run udactable function to download and save latest submission tables
              return this.$store
                .dispatch('ocpu/odataSubmissions', prop)
                .then(() => {
                  // Hide loading dialog
                  this.loadDialog = false
                  // this.$store.dispatch({
                  //   type: 'ocpu/runOcpuCode',
                  //   pkey: pkey,
                  // })
                })
            } else {
              // Hide loading dialog
              this.loadDialog = false
            }
          })
      },

      startTour () {
        // console.log('ahaf=')
        // this.$tours.myTour.start()
      },

    },
  }
</script>

<style>
/* https://stackoverflow.com/a/64124512 */
.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}
</style>
