import { defineStore } from 'pinia'

export const useBendStore = defineStore('bendStore', {

  //     /* -------------------------------------------------
  //     //                      STATE
  //     ================================================== */
  state: () => ({
    calls: { organization: false, projects: false, webuser: false },
    onlineProjects: [],
    organization: { allStaff: [], currentStaff: [], projects: [] },
    activeForm: {},
  }),

  //     /* -------------------------------------------------
  //     //                     GETTERS
  //     ================================================== */
  getters: {
    doubleCount: (state) => state.counter * 2,
  },

  //     /* -------------------------------------------------
  //     //                     ACTIONS
  //     ================================================== */
  actions: {
    increment () {
      this.counter++
    },
  },
})
// defineStore({
//   id: 'bendStore',
//   options: {
//     /* -------------------------------------------------
//     //                      STATE
//     ================================================== */
//     state: () => ({
//       calls: {
//         organization: false,
//         projects: false,
//         webuser: false,
//       },
//       onlineProjects: [],
//       organization: { allStaff: [], currentStaff: [], projects: [] },
//       activeForm: {},
//     }),

//   // actions

//   // getters
//   },
// })
