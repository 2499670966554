/**
 * There are two type of users: Application User (data collector) and Web User (Staff)
 * This code manages Web users - Staff or Clients of the Organization
 */
export default {
  methods: {
    /**
     * Add a new user to Ogranization. Logic:
     * 1. Prepare data body for post request
     * 2. Register a new web user with 'observer' role
     * 3. Patch Organization to include new web user
     *  3.2. Prepare Organization's users: concat new and existing then commit
     */
    async postWebuser (props) {
      // Get valid token
      await this.$store.dispatch('auth/getToken')
      // console.log('postWebuser')
      // Prepare variables
      const org = this.$store.state.bend.organization
      const newStaff = {}

      // variable to store newly created project's json id
      let newid = ''
      const existingstaff = org.allStaff
      // const newproject = []

      // STEP 3.1. Patch Organization to add new web user
      const patchOrganization = async (newStaff) => {
        // console.log('patchOrganization')

        const nuser = {
          type: 'user--user',
          id: `${newid}`,
        }
        const body = {}
        body.data = []

        // Preserve excisting users while adding a new web user
        // loop existing project and prepare post body
        existingstaff.forEach((item, i) => {
          body.data[i] = {}
          body.data[i].type = 'user--user'
          body.data[i].id = item.uuid
        })
        // include new webusers to the beginning
        body.data.unshift(nuser)

        // Patch Organization and add web users
        return new Promise((resolve, reject) => {
          this.$httpBend
            .patch('jsonapi/node/organization/' + org.id + '/relationships/field_staff', body, {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
                'Content-Type': 'application/vnd.api+json',
                Accept: 'application/vnd.api+json',
              },
            })
            .then(response => {
              // console.log(response)
              if (response.status === 204) {
                // show information about success
                this.snackBar = {
                  type: 'success',
                  mode: 'multi-line',
                  text: `New staff user - '${this.username}' is successfully added`,
                  visible: true,
                }

                // STEP 3.2. Prepare Organization's users: concat new and existing then commit
                const updatedstaff = existingstaff.concat(newStaff)
                this.$store.commit('bend/addToOrganization', { allStaff: updatedstaff })

                // close 'New Staff' dialogue
                this.dialogAddUser = false
              } else {
                // return resolve(false)
              }
            })
            .catch(error => {
              // console.log(error)
              return reject(error.message)
            })
        })
      }

      // STEP 1. Prepare data body for post request
      const data = {
        name: [{ value: this.username }],
        mail: [{ value: this.email }],
        pass: [{ value: this.password }],
        field_type: [{ value: this.selectedType.type }],
        status: [{ value: '1' }],
        // field_odk_server: [{ uri: org.odk_server }],
        field_organization: [{ target_id: org.nid }],
        roles: [{ target_id: 'observer' }],
      }
      // console.log(data)

      // STEP 2. Register a new web user with 'observer' role
      return new Promise((resolve, reject) => {
        this.$httpBend.post('/entity/user?_format=json', data, {
          headers: {
            Authorization: `Bearer ${this.$store.state.auth.token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
          .then(response => {
            // console.log(response)

            if (response.status === 201) {
              newid = response.data.uuid[0].value
              newStaff.uid = response.data.uid[0].value
              newStaff.uuid = response.data.uuid[0].value
              newStaff.changed = response.data.changed[0].value
              newStaff.created = response.data.created[0].value
              newStaff.name = response.data.name[0].value
              newStaff.email = response.data.mail[0].value
              newStaff.status = response.data.status[0].value
              newStaff.type = response.data.field_type[0].value
              const roles = []
              for (let r = 0; r < response.data.roles.length; r++) {
                const label = response.data.roles[r].target_id
                const crole = {}
                switch (label) {
                  case 'client':
                    crole.level = 'Account owner'
                  break
                  case 'vip':
                    crole.level = 'VIP Client'
                  break
                  default:
                    crole.level = 'Web user'
                    break
                }
                crole.label = label
                roles.push(crole)
              }
              newStaff.roles = roles
              // STEP 3. send to Patch Organization to add new web user
              patchOrganization(newStaff)
            }
            resolve(response)
          })
          .catch(error => {
            // console.log(error.response)
            this.snackBar = {
              type: 'error',
              mode: 'multi-line',
              title: `${this.$t('common.error')}`,
              text: error.response.data.message,
              visible: true,
            }
            resolve(error.response)
          })
        })
    },

    /**
     * Get all users of the organization the current user belongs.
     *  Logic:
     * 1. Prepare object of endpoint of bend to be called
     *  (uses api/%/staff (drupal views) and api/current_user (drupal views))
     * 2. Call each endpoint with for loop (main function)
     * 3. Commit webuser call into TRUE
     */
    async getWebuser () {
      // Get valid token
      await this.$store.dispatch('auth/getToken')
      // console.log('getWebuser')
      // Function to get user information from bend
      const getStaff = (data) => {
        // STEP 1. Call endpoint to get user info
        return new Promise((resolve, reject) => {
          this.$httpBend
            .get(data.endpoint, {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
                'Content-Type': 'application/vnd.api+json',
                Accept: 'application/vnd.api+json',
              },
            })
            .then(response => {
              // console.log(data.endpoint)
              // console.log(response)
              if (response.data.length > 0) {
                const staffInfo = []
                for (var i = 0, len = response.data.length; i < len; i++) {
                  const obj = {}
                  const roles = []
                  obj.uid = response.data[i].uid[0].value
                  obj.uuid = response.data[i].uuid[0].value
                  obj.changed = response.data[i].changed[0].value
                  obj.created = response.data[i].created[0].value
                  obj.name = response.data[i].name[0].value
                  obj.type = response.data[i].field_type[0].value
                  obj.email = response.data[i].mail[0].value
                  obj.status = response.data[i].status[0].value
                  for (let r = 0; r < response.data[i].roles.length; r++) {
                    const label = response.data[i].roles[r].target_id
                    const crole = {}
                    switch (label) {
                      case 'client':
                        crole.level = 'Account owner'
                      break
                      case 'vip':
                        crole.level = 'VIP Client'
                      break
                      default:
                        crole.level = 'Web user'
                        break
                    }
                    crole.label = label
                    roles.push(crole)
                  }
                  obj.roles = roles
                  staffInfo.push(obj)
                }
                // STEP 1.2 Commit user info into organization
                const foo = {}
                foo[data.name] = staffInfo
                this.$store.commit('bend/addToOrganization', foo)

                // console.log(foo)
                return resolve(true)
              }
            })
            .catch(error => {
              // console.log(error.message)
              return reject(error.message)
            })
        })
      }

      // STEP 1. Prepare object about endpoint of the bend to get user info
      const userCalls = [
        { name: 'currentStaff', endpoint: 'api/current_user', ready: true },
        { name: 'allStaff', endpoint: `api/${this.$store.state.bend.organization.nid}/staff`, ready: 'id' in this.$store.state.bend.organization },
      ]

      // STEP 2. Call function for each endpoint
      for (let i = 0; i < userCalls.length; i++) {
        // console.log(userCalls[i].ready + ' - ' + userCalls[i].name)
        if (userCalls[i].ready === true) {
          await getStaff(userCalls[i])
        } else {
          const foo = {}
          foo[userCalls[i].name] = []
          this.$store.commit('bend/addToOrganization', foo)
        }
      }

      // STEP 3. Commit webuser call into TRUE
      this.$store.commit('bend/setCalls', { webuser: true })
    },

    /**
     * 1. Delete user entity from organization node in bend
     *  1.1 Prepare left users of the organization
     *  1.2 Patch Organization and re-write only left projects
     * 2. Delete User entity from bend
     * 3. Commit vuex to set left projects
     * 4. Run ocdeleteproject() function in ocpu to delete project folder completely in OCPU Server
     */
    async deleteWebuser (prop) {
      // Get valid token
      await this.$store.dispatch('auth/getToken')
      const org = this.$store.state.bend.organization

      // STEP 2. Delete User entity from bend
      const deleteUserEntity = () => {
        return new Promise((resolve, reject) => {
          this.$httpBend.delete('/jsonapi/user/user/' + prop.uuid, {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
          })
        .then(response => {
          // console.log(response)
          if (response.status === 204) {
            // close 'New Staff' dialogue
            this.dialogDelete = false
            // show information
            this.snackBar = {
              type: 'info',
              mode: 'multi-line',
              text: `Web user - '${prop.name}' is deleted`,
              visible: true,
            }
          } else {
            // return resolve(false)
          }
        })
        .catch(error => {
          // console.log(error)
          return reject(error.message)
        })
        })
      }

      // STEP 1. Delete user entity from organization node in bend
      // STEP 1.1. Prepare left users of the organization

      const leftUsers = org.allStaff.filter(s => s.uuid !== prop.uuid)
      // same as above result
      // const index = org.allStaff.map(e => e.uuid).indexOf(prop.uuid)
      // const userjsonid = org.allStaff[index].uuid
      // const leftUsers = org.projects.filter(p => p.id !== userjsonid)

      // console.log(leftUsers)
      const body = {}
      body.data = []
      // if Organization has any other projects we should keep them
      if (leftUsers.length > 0) {
        // loop existing organization and prepare post body
        leftUsers.forEach((item, i) => {
          body.data[i] = {}
          body.data[i].type = 'user--user'
          body.data[i].id = item.uuid
        })
      }
      // console.log(body)

      // STEP 1.2 Patch Organization and re-write only left projects
      return new Promise((resolve, reject) => {
        this.$httpBend
          .patch('jsonapi/node/organization/' + org.id + '/relationships/field_staff', body, {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
          })
          .then(response => {
            // console.log(response)
            if (response.status === 204) {
              // STEP 2. Delete User entity from bend
              deleteUserEntity()

              // STEP 3. Commit vuex to set left users - Staff
              this.$store.commit('bend/addToOrganization', { allStaff: leftUsers })
            } else {
              // return resolve(false)
            }
            // console.log(response)
          })
          .catch(error => {
            // console.log(error)
            return reject(error.message)
          })
      })
    },
  },
}
